<template>
  <div class="vx-row mb-4">
    <div class="vx-col mb-6 w-full">
      <h4 class="text-primary">Address</h4>
    </div>

    <div class="vx-col mb-4 w-full md:w-1/2 lg:w-1/3">
      <vs-input
        v-model="value.address.house"
        name="house"
        class="w-full"
        label="House Number"
        color="dark"/>
      <span class="text-danger text-sm block mt-2" v-show="errors.has('house')">
        {{ errors.first('house') }}
      </span>
    </div>

    <div class="vx-col mb-4 w-full md:w-1/2 lg:w-1/3">
      <vs-input
        v-model="value.address.street"
        name="street"
        class="w-full"
        label="Street"
        color="dark"/>
      <span class="text-danger text-sm block mt-2" v-show="errors.has('street')">
        {{ errors.first('street') }}
      </span>
    </div>

    <div class="vx-col mb-4 w-full md:w-1/2 lg:w-1/3">
      <label class="vs-input--label">City</label>
      <v-select
        v-model="value.address.city"
        name="city"
        class="w-full"
        color="dark"
        :options="cityOptions"
        @input="value.address.district = null; value.address.ward = null"
        label="name"
        v-validate="'required'"/>
      <span class="text-danger text-sm block mt-2" v-show="errors.has('city')">
        {{ errors.first('city') }}
      </span>
    </div>

    <div class="vx-col mb-4 w-full md:w-1/2 lg:w-1/3">
      <label class="vs-input--label">District</label>
      <v-select
        v-model="value.address.district"
        name="district"
        class="w-full"
        color="dark"
        :options="districtOptions"
        label="name"
        @input="value.address.ward = null"
        :disabled="!value.address.city"/>
      <span class="text-danger text-sm block mt-2" v-show="errors.has('district')">
        {{ errors.first('district') }}
      </span>
    </div>

    <div class="vx-col mb-4 w-full md:w-1/2 lg:w-1/3">
      <label class="vs-input--label">Ward</label>
      <v-select
        v-model="value.address.ward"
        name="ward"
        class="w-full"
        color="dark"
        :options="wardOptions"
        label="name"
        :disabled="!value.address.district"/>
      <span class="text-danger text-sm block mt-2" v-show="errors.has('ward')">
        {{ errors.first('ward') }}
      </span>
    </div>

    <div class="vx-col mb-4 w-full md:w-1/2 lg:w-1/3">
      <vs-input v-model="value.address.zip_code"
        name="zip_code"
        class="w-full"
        label="Zip Code"
        color="dark"/>
      <span class="text-danger text-sm block mt-2" v-show="errors.has('zip_code')">
        {{ errors.first('zip_code') }}
      </span>
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import { Validator } from 'vee-validate'
import validateMessage from '@/translations/validate/member/address'
import schemas from '@/utils/schemas'

Validator.localize('en', { custom: validateMessage })

export default {
  name: 'MemberCreateAddress',
  components: {
    vSelect,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      schema: schemas.memberAddress(),
    }
  },

  computed: {
    ...mapGetters('data', ['cities']),

    cityOptions () {
      if (this.cities === undefined
        || this.cities.length === 0) {
        return []
      }

      return this.cities.map((city) => city.name)
    },
    districtOptions () {
      if (this.districts === undefined
        || this.districts.length === 0) {
        return []
      }

      return this.districts.map((district) => district.name)
    },
    wardOptions () {
      if (this.wards === undefined
        || this.wards.length === 0) {
        return []
      }

      return this.districts.map((district) => district.name)
    },

    districts () {
      if (this.cities === undefined
        || this.cities.length === 0
        || !this.value.address.city
        || this.value.address.city.length === 0) {
        return []
      }

      const _city = this.cities.find((city) => city.name === this.value.address.city)

      return this.lodash.get(_city, 'districts.data') || []
    },

    wards () {
      if (this.cities === undefined
        || this.cities.length === 0
        || !this.value.address.district
        || this.value.address.district.length === 0) {
        return []
      }

      const _district = this.districts.find((district) => district.name === this.value.address.district)

      return this.lodash.get(_district, 'wards.data') || []
    },
  },

  methods: {
    ...mapActions('data', ['fetchCities']),

    validate () {
      return this.$validator.validateAll()
    },
  },

  mounted () {
    if (this.cities.length === 0) {
      this.fetchCities()
    }
  },

  watch: {
    // v    // }
  },
}
</script>
