<template>
  <div class="vx-row mb-4">
    <div class="vx-col mb-6 w-full">
      <h4 class="text-primary">Profile</h4>
    </div>

    <div class="vx-col mb-4 w-full md:w-1/2 lg:w-1/3">
      <vs-input
        v-model="value.profile.name"
        name="name"
        class="w-full"
        label="Name"
        color="dark"
        v-validate="'required'"/>
      <span class="text-danger text-sm block mt-2" v-show="errors.has('name')">
        {{ errors.first('name') }}
      </span>
    </div>

    <div class="vx-col mb-4 w-full md:w-1/2 lg:w-1/3">
      <label class="vs-input--label">Birthdate</label>
      <datepicker
        v-model="value.profile.birthdate"
        name="birthdate"
        placeholder="Select Date"
        color="dark"
        :format="datePicker.format"
        :disabled-dates="datePicker.disabledDates" />
      <span class="text-danger text-sm block mt-2" v-show="errors.has('birthdate')">
        {{ errors.first('birthdate') }}
      </span>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import { Validator } from 'vee-validate'
import validateMessage from '@/translations/validate/member/profile'
Validator.localize('en', { custom: validateMessage })

export default {
  name: 'MemberCreateProfile',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  components: {
    Datepicker,
  },
  data () {
    return {
      datePicker: {
        format: 'd MMMM yyyy',
        disabledDates: {
          from: new Date(),
        },
      },
    }
  },

  methods: {
    validate () {
      return this.$validator.validateAll()
    },
  },

  watch: {
    // value () {
    //   this.$emit('input', this.value)
    // }
  },
}
</script>
