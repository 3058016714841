<template>
  <div class="vx-row mb-4">
    <div class="vx-col mb-6 w-full">
      <h4 class="text-primary">Member</h4>
    </div>

    <div class="vx-col mb-4 w-full">
      <vs-radio
        v-model="value.profile.club"
        name="profile_club"
        vs-value="blue"
        class="inline-flex"
        color="primary"
        v-validate="'required'">
        Blue Club
      </vs-radio>
      <span class="text-danger text-sm block" v-show="errors.has('profile_club')">
        {{ errors.first('profile_club') }}
      </span>
    </div>

    <div class="vx-col mb-4 w-full md:w-1/2 lg:w-1/3">
      <vs-input
        v-model="value.profile.email"
        name="email"
        ref="email"
        class="w-full"
        label="Email"
        color="dark"
        v-validate="{ email: true }"/>
      <span class="text-danger text-sm block mt-2" v-show="errors.has('email')">
        {{ errors.first('email') }}
      </span>
    </div>

    <div class="vx-col mb-4 w-full md:w-1/2 lg:w-1/3">
      <label for="phone_number">Contact Number</label>
      <vx-input-group>
        <template slot="prepend">
          <div class="prepend-text bg-primary">
            <span>84</span>
          </div>
        </template>
        <vs-input
          v-model="value.profile.phone_format"
          name="phone_number"
          ref="phone_number"
          class="w-full"
          color="dark"
          v-validate="{ required: phoneNumberIsRequired, numeric: true, min: 7, max: 10, regex: /^[0-9]{1,10}/ }"
          maxlength="10"
        />
      </vx-input-group>
      <span class="text-danger text-sm block mt-2" v-show="errors.has('phone_number')">
        {{ errors.first('phone_number') }}
      </span>
    </div>

    <div class="vx-col mb-4 w-full md:w-1/2 lg:w-1/3">
      <label class="flex mb-2">Contact Channel</label>
      <div class="w-full">
        <vs-checkbox
          v-model="value.profile.contact_channel"
          vs-value="address"
          name="contact_channel_address"
          class="inline-flex"
          color="primary">
          Address
        </vs-checkbox>
        <vs-checkbox
          v-model="value.profile.contact_channel"
          name="contact_channel_channel"
          vs-value="phone"
          class="inline-flex"
          color="primary">
          Phone
        </vs-checkbox>
        <vs-checkbox
          v-model="value.profile.contact_channel"
          name="contact_channel_sms"
          vs-value="sms"
          class="inline-flex"
          color="primary">
          SMS
        </vs-checkbox>
        <vs-checkbox
          v-model="value.profile.contact_channel"
          name="contact_channel_email"
          vs-value="email"
          class="inline-flex"
          color="primary">
          Email
        </vs-checkbox>
      </div>
    </div>

  </div>
</template>

<script>
import { Validator } from 'vee-validate'
import validateMessage from '@/translations/validate/member/member'
Validator.localize('en', { custom: validateMessage })

export default {
  name: 'MemberCreate',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  computed: {
    emailIsRequired () {
      const value = this.lodash.get(this, 'value.profile.phone_number') || ''

      return value.length === 0
        || this.lodash.intersection(
          ['email'],
          this.value.profile.contact_channel,
        ).length !== 0
    },
    phoneNumberIsRequired () {
      const value = this.lodash.get(this, 'value.profile.email') || ''

      return value.length === 0
        || this.lodash.intersection(
          ['sms', 'phone'],
          this.value.profile.contact_channel,
        ).length !== 0
    },
  },

  methods: {
    validate () {
      return this.$validator.validateAll()
    },
  },

  watch: {
    // value () {
    //   this.$emit('input', this.value)
    // }
  },
}
</script>
