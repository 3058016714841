export default {
  profile_club: {
    required: 'Please select club for member',
  },
  email: {
    required: 'Please enter email address',
    email: 'Please enter valid email address',
  },
  phone_number: {
    required: 'Please enter phone number',
    numeric: 'Please enter valid phone number',
    min: 'Please enter valid phone number',
  },
}
