<template>
  <div class="vx-row mb-4">
    <div class="vx-col mb-6 w-full">
      <h4 class="text-primary">Gift Sets</h4>
    </div>

    <div class="vx-col mb-4 w-full">
      <div
        v-for="giftSet in giftSets"
        :key="giftSet.id">

        <div class="flex items-center mb-4">
          <vs-switch
            v-model="value.gift_set"
            :vs-value="giftSet.slug"
            color="success"/>
          <span class="ml-4">{{ giftSet.name }}</span>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('data', ['giftSets']),
  },

  methods: {
    ...mapActions('data', ['fetchGiftSets']),

    validate () {
      return this.$validator.validateAll()
    },
  },

  async mounted () {
    if (this.giftSets.length === 0) {
      await this.fetchGiftSets()
    }
  },

  watch: {
    // value () {
    //   this.$emit('input', this.value)
    // }
  },
}
</script>
