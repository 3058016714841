<template>
  <div class="vx-row mb-4">
    <div class="vx-col mb-6 w-full">
      <h4 class="text-primary">Children</h4>
    </div>

    <div class="vx-col w-full">
      <div class="vx-row">
        <div class="vx-col mb-4 w-full md:w-1/2 lg:w-1/3">
          <label class="vs-input--label">Pregnancy</label>
          <div class="vs-con-input mt-2">
            <ul class="flex">
              <li class="mx-1">
                <vs-radio v-model="value.children.pregnant"
                  vs-name="pregnancy"
                  name="pregnant"
                  :vs-value="true"
                  v-validate="'required'">
                  Yes
                </vs-radio>
              </li>
              <li class="mx-1">
                <vs-radio v-model="value.children.pregnant"
                  vs-name="pregnancy"
                  name="pregnant"
                  :vs-value="false"
                  v-validate="'required'">
                  No
                </vs-radio>
              </li>
            </ul>
          </div>
          <span class="text-danger text-sm block" v-show="errors.has('pregnant')">
            {{ errors.first('pregnant') }}
          </span>
        </div>

        <div class="vx-col mb-4 w-full md:w-1/2 lg:w-1/3" v-if="!value.children.pregnant">
          <label class="vs-input--label">Delivered</label>
          <v-select v-model="value.children.delivered"
            class="w-full"
            name="delivered"
            color="dark"
            :options="deliveredOptions"
            :reduce="delivered => delivered.value"/>
          <span class="text-danger text-sm block" v-show="errors.has('delivered')">
            {{ errors.first('delivered') }}
          </span>
        </div>

        <div class="vx-col mb-4 w-full md:w-1/2 lg:w-1/3" v-if="!value.children.pregnant">
          <label class="vs-input--label">Gender</label>
          <div class="vs-con-input mt-2">
            <ul class="flex">
              <li class="mx-1">
                <vs-radio v-model="value.children.gender"
                  vs-name="gender"
                  name="gender"
                  vs-value="boy"
                  v-validate="{ required: genderIsRequired }">
                  Boy
                </vs-radio>
              </li>
              <li class="mx-1">
                <vs-radio v-model="value.children.gender"
                  vs-name="gender"
                  name="gender"
                  vs-value="girl"
                  v-validate="{ required: genderIsRequired }">
                  Girl
                </vs-radio>
              </li>
            </ul>
          </div>
          <span class="text-danger text-sm block" v-show="errors.has('gender')">
            {{ errors.first('gender') }}
          </span>
        </div>
      </div>
    </div>

    <template v-if="value.children.pregnant">
      <div class="vx-col mb-4 w-full md:w-1/2 lg:w-1/3">
        <label class="vs-input--label">Due Date</label>
        <datepicker v-model="estimatedDueDate"
          placeholder="Select Date"
          name="estimated_due_date"
          color="dark"
          :format="datePicker.format"
          :disabled-dates="datePicker.disabledDatesForDueDate"
          v-validate="'required'"/>

          <span class="text-danger text-sm mt-2" v-show="errors.has('estimated_due_date')">
            {{ errors.first('estimated_due_date') }}
          </span>
      </div>

      <div class="vx-col mb-4 w-full md:w-1/2 lg:w-1/3">
        <vs-input :value="estimatedDueDate | pregnancyWeeks"
          class="w-full"
          name="pregnancy_week"
          color="dark"
          label="Pregnancy Weeks"
          disabled="true" />
      </div>
    </template>

    <template v-if="!value.children.pregnant">
      <div class="vx-col mb-4 w-full md:w-1/2 lg:w-1/3">
        <vs-input v-model="value.children.name"
          label="Name"
          class="w-full"
          name="child_name"
          color="dark"
          v-validate="{ required: nameIsRequired }"/>
        <span class="text-danger text-sm block" v-show="errors.has('child_name')">
          {{ errors.first('child_name') }}
        </span>
      </div>

      <div class="vx-col mb-4 w-full md:w-1/2 lg:w-1/3">
        <label class="vs-input--label">Birthdate</label>
        <datepicker
          v-model="value.children.birthdate"
          placeholder="Select Date"
          name="child_birthdate"
          color="dark"
          :format="datePicker.format"
          :disabled-dates="datePicker.disabledDatesForBirthdate"
          v-validate="{ required: birthdateIsRequired }"
        />
          <span class="text-danger text-sm mt-2" v-show="errors.has('child_birthdate')">{{ errors.first('child_birthdate') }}</span>
      </div>

      <div class="vx-col mb-4 w-full md:w-1/2 lg:w-1/3">
        <vs-input :value="value.children.birthdate | age"
          label="Age"
          class="w-full"
          name="child_age"
          color="dark"
          disabled="true" />
      </div>
    </template>

    <div class="vx-col mb-4 w-full md:w-1/2 lg:w-1/3">
      <label class="vs-input--label">Current Product Using</label>
      <v-select v-model="value.children.products"
        class="w-full"
        name="products"
        color="dark"
        :options="productOptions"
        :reduce="product => product.slug"/>
      <span class="text-danger text-sm block mt-2" v-show="errors.has('products')">
        {{ errors.first('products') }}
      </span>
    </div>

    <div class="vx-col mb-4 w-full md:w-1/2 lg:w-1/3">
      <label class="vs-input--label">Buy Product From</label>
      <v-select v-model="value.children.stores"
                class="w-full"
                name="stores"
                color="dark"
                :options="storeOptions"
                :reduce="store => store.slug"/>
      <span class="text-danger text-sm block mt-2" v-show="errors.has('stores')">
        {{ errors.first('stores') }}
      </span>
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import Datepicker from 'vuejs-datepicker'
import vSelect from 'vue-select'

import { Validator } from 'vee-validate'
import validateMessage from '@/translations/validate/member/children'
Validator.localize('en', { custom: validateMessage })

import constants from '@/utils/constants'

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  components: {
    Datepicker,
    vSelect,
  },

  data () {
    return {
      deliveredOptions: constants.childrenDeliveredOptions,
      dateFormat: 'DD MMM YYYY',
      datePicker: {
        format: 'd MMMM yyyy',
        disabledDatesForBirthdate: {
          from: new Date(),
        },
        disabledDatesForDueDate: {
          to: new Date(),
        },
      },
    }
  },

  filters: {
    pregnancyWeeks (value) {
      if (!value) {
        return ''
      }

      const week = 40 - moment(value).diff(new Date(), 'weeks')

      if (week > 40) {
        return 'Over due date'
      }

      if (week < 0) {
        return 'Invalid value'
      }

      return `${week} weeks`
    },

    age (value) {
      if (!value) {
        return ''
      }

      const ageInMonth = moment().diff(value, 'months')

      if (ageInMonth === 0) {
        const day = moment().diff(value, 'days')
        return `${day} day${day > 1 ? 's' : ''}`
      }

      const year = Math.floor(ageInMonth / 12)
      const month = ageInMonth % 12

      const age = []

      if (year > 0) {
        age.push(`${year} year${year > 1 ? 's' : ''}`)
      }

      if (month > 0) {
        age.push(`${month} month${month > 1 ? 's' : ''}`)
      }

      return age.join(' ')
    },
  },

  computed: {
    ...mapGetters('data', ['products', 'stores']),

    nameIsRequired () {
      return !this.lodash.get(this, 'value.children.pregnant')
    },

    genderIsRequired () {
      return !this.lodash.get(this, 'value.children.pregnant')
    },

    birthdateIsRequired () {
      // return !this.lodash.get(this, 'value.children.pregnant')
      // return false for demo
      return false
    },

    productOptions () {
      return this.products.map((product) => ({
        label: product.title,
        slug: product.slug,
      }))
    },

    storeOptions () {
      return this.stores.map((store) => ({
        label: store.title,
        slug: store.slug,
      }))
    },

    estimatedDueDate: {
      get () {
        return this.value.children.estimated_due_date
      },
      set (edd) {
        this.value.children.estimated_due_date = this.$moment(edd).format('YYYY-MM-DD')
      },
    },
  },

  methods: {
    ...mapActions('data', ['fetchProducts', 'fetchStores']),

    validate () {
      return this.$validator.validateAll()
    },
  },

  async mounted () {
    if (this.products.length === 0) {
      this.fetchProducts()
    }

    if (this.stores.length === 0) {
      this.fetchStores()
    }
  },

  watch: {
    // value () {
    //   this.$emit('input', this.value)
    // }
  },
}
</script>
