<template>
  <section>
    <vs-row vs-justify="center" class="vx-row w-auto">
      <vs-col class="vx-col w-full">
        <vx-card class="cardx">
          <member v-model="member" ref="member" />

          <member-profile v-model="member" ref="profile" />

          <member-address v-model="member" ref="address" />

          <member-children v-model="member" ref="children" />

          <member-gift-set v-model="member" ref="giftSet" />

          <div class="vx-row">
            <div class="vx-col w-full text-center">
              <vs-button
                class="mx-auto"
                icon-pack="feather"
                icon="icon-save"
                @click.prevent="createMember"
                :disabled="locked"
              >
                Create New Member
              </vs-button>
            </div>
          </div>
        </vx-card>
      </vs-col>
    </vs-row>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
// import { Validator } from 'vee-validate'

import infoMixin from '@/mixins/infoMixin'
// import constants from '@/utils/constants'
import schemas from '@/utils/schemas'
// import validateMessage from '@/translations/validate/member/profile'
// Validator.localize('en', { custom: validateMessage })

// import CustomVsTable from '@/components/table/CustomVsTable'
// import VSelect from 'vue-select'
import Member from '@/components/form/member-create/member'
import MemberProfile from '@/components/form/member-create/profile'
import MemberAddress from '@/components/form/member-create/address'
import MemberChildren from '@/components/form/member-create/children'
import MemberGiftSet from '@/components/form/member-create/giftSet'

export default {
  mixins: [infoMixin],
  components: {
    Member,
    MemberProfile,
    MemberAddress,
    MemberChildren,
    MemberGiftSet,
  },
  data () {
    const profile = schemas.memberProfile()
    profile.club = 'blue'
    return {
      member: {
        profile,
        address: schemas.memberAddress(),
        children: schemas.memberChildren(),
        gift_set: [],
      },
      datePicker: {
        birthdate: {
          format: 'd MMMM yyyy',
          disabledDates: {
            from: new Date(),
          },
        },
      },
    }
  },

  methods: {
    ...mapActions('member', ['create']),

    async validateForm () {
      const validates = [
        this.$refs.member.validate(),
        this.$refs.profile.validate(),
        this.$refs.address.validate(),
        this.$refs.children.validate(),
        this.$refs.giftSet.validate(),
      ]

      const valids = await Promise.all(validates)

      return valids.every((valid) => valid)
    },

    async createMember () {
      const valid = await this.validateForm()

      /* eslint-disable */
      if (!valid) {
        this.$vs.notify({
          title: 'Error',
          text: 'Please complete this form',
          iconPack: 'feather',
          icon: 'icon-error',
          color: 'warning',
        })
        return
      }

      const member = this.member

      if (this.lodash.isDate(member.profile.birthdate)) {
        member.profile.birthdate = this.$moment(
          member.profile.birthdate
        ).format('YYYY-MM-DD')
      }
      if (this.lodash.isDate(member.children.estimated_due_date)) {
        member.children.estimated_due_date = this.$moment(
          member.children.estimated_due_date
        ).format('YYYY-MM-DD')
      }
      if (this.lodash.isDate(member.children.birthdate)) {
        member.children.birthdate = this.$moment(
          member.children.birthdate
        ).format('YYYY-MM-DD')
      }

      if (member.profile.phone_format !== '') {
        member.profile.phone_number = '84' + member.profile.phone_format
      }

      this.locked = true

      await Promise.all([this.create({ data: member })])
        .then(() => {
          this.$vs.notify({
            title: 'Success',
            text: 'Member has been created',
            iconPack: 'feather',
            icon: 'icon-check-circle',
            color: 'success',
          })

          this.$router.push({ name: 'member' }).catch(() => {})
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Error',
            text: 'Create fail',
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger',
          })
        })
        .finally(() => {
          this.locked = false
        })
    },
  },
}
</script>
