export default {
  estimated_due_date: {
    required: 'Please select due date',
  },
  child_name: {
    required: 'Please enter child name',
  },
  child_birthdate: {
    required: 'Please select child birthdate',
  },
}
